export default {
  // url_config: `https://wss.thisdemo.net/test-api`,
  // static_url: `https://wss.thisdemo.net/test-api`,
  // wss_url: 'wss://wss.thisdemo.net/om',
  // wss_url2: 'wss://wss.thisdemo.net/im',
  url_config: `https://www.wensanshi.com/prod-api`,
  static_url: `https://www.wensanshi.com/prod-api`,
  wss_url: 'wss://www.wensanshi.com/im',
  
  // wss_url2: 'wss://www.wensanshi.com/im',
  // url_config: `https://www.wensanshi.com/prod-api`, 
  // static_url: `https://www.wensanshi.com/prod-api`,
  // wss_url: "ws://www.wensanshi.com:8889",
  // wss_url2: "ws://www.wensanshi.com:8890",
  // url_config: `http://47.106.222.181:8888`,
  // static_url: `http://47.106.222.181:8888`, 
  // wss_url: 'ws://47.106.222.181:8889',
  // wss_url2: 'ws://47.106.222.181:8890',
  // url_config:"https://20d37u4572.oicp.vip",//小续  
  // url_config:"http://192.168.3.13:7001",//小续
  // static_url:"http://192.168.3.13:7001",
  // wss_url:"ws://192.168.3.13:7002",
  // wss_url2:"ws://192.168.3.13:7003",
  // url_config:"http://192.168.3.73:7001",//龙哥
  // static_url:"http://192.168.3.73:7001",
  // wss_url:"ws://192.168.3.73:8002",
  // wss_url2:"ws://192.168.3.21:7003",
  // url_config: "http://192.168.3.72:7001", //雷公
  // static_url: "http://192.168.3.72:7001",
  // wss_url:"ws://192.168.3.72:8002",
  // url_config: "http://192.168.3.250:7001", //雷工
  // static_url: "http://192.168.3.250:7001",
  // wss_url:"ws://192.168.3.250:8002",
  // url_config: "http://192.168.3.203:7002", //铭工
  // static_url: "http://192.168.3.203:7002",
  // wss_url:"ws://192.168.3.203:7002",
  // url_config: "http://192.168.3.203:7001", //铭工
  // static_url: "http://192.168.3.203:7001",
  // wss_url:"ws://192.168.3.203:8002", 
 //  url_config: "http://101.43.126.63:7001", //雷工 云服务器
 //  static_url: "http://101.43.126.63:7001",
	// wss_url:"ws://101.43.126.63:7002",
	// url_config: "http://192.168.3.76:7001", //谢工
	// static_url: "http://192.168.3.76:7001",
	// wss_url:"ws://192.168.3.76:7001",
	// url_config: "http://192.168.3.203:7001", //铭工
	// static_url: "http://192.168.3.203:7001",
	// wss_url:"ws://192.168.3.203:7001",
 
	// url_config: "http://192.168.3.72:7001", //雷公
	// static_url: "http://192.168.3.72:7001",
	// wss_url:"ws://192.168.3.72:8002",

  // url_config: "http://192.168.3.144:7001", //江
  // static_url: "http://192.168.3.144:7001",
  // wss_url:"ws://192.168.3.144:8002",
};   
