// src/api.js  
import axios from 'axios';  
const urlLocal={
  // url:'http://192.168.3.73:7001',//龙哥
  // url:'http://192.168.3.203:7001',//陈工
  // url:'http://192.168.3.72:7001',//雷工
  // url:"http://192.168.3.169:7001",//高工

  url:"https://www.wensanshi.com/prod-api",//线上
  // url:'http://47.106.222.181:7101'
}
// 创建 axios 实例  
const service = axios.create({  
  // baseURL: 'https://www.wensanshi.com/prod-api',//线上服务器

  // baseURL: 'http://192.168.3.203:7001', // 这里可以设置你的基础 URL  
  baseURL:process.env.VUE_APP_ENV === 'production'? 'https://www.wensanshi.com/prod-api': urlLocal.url,//线上服务器
  // baseURL:process.env.VUE_APP_ENV === 'production'? 'http://47.106.222.181:7001': urlLocal.url,//测试服务器
  // baseURL:process.env.VUE_APP_ENV === 'production'? 'http://47.106.222.181:7101': urlLocal.url,//陈工服务器

  timeout: 5000 // 请求超时时间  
});  
  
// 请求拦截器  
service.interceptors.request.use(  
  config => {  
    // 在发送请求之前做些什么，比如设置 token 到 headers  
    // const token = localStorage.getItem('token'); // 假设你的 token 存储在 localStorage 中  
    // if (token) {  
    //   config.headers['Authorization'] = `Bearer ${token}`;  
    // }  
    return config;  
  },  
  error => {  
    // 对请求错误做些什么  
    console.log(error); // for debug  
    return Promise.reject(error);  
  }  
);  
  
// 响应拦截器  
service.interceptors.response.use(  
  response => {  
    // 对响应数据做点什么  
    return response.data;  
  },  
  error => {  
    // 对响应错误做点什么  
    console.log(error); // for debug  
    return Promise.reject(error);  
  }  
);  
  


// 下面写请求
// 封装一个用于上传文件的 post 请求  
export function uploadFile( formData,token) {  
  return service.post('/rdf/mrt/uploadFile', formData, {  
    headers: {  
      'Content-Type': 'multipart/form-data',
      "Authorization":token
    }  
  });  
}  
// 添加模板
export function reportMrtAdd( data,token) {  
  return service.post('/rdf/mrt/add', data, {  
    headers: {  
      // 'Content-Type': 'multipart/form-data',
      "Authorization":token
    }  
  });  
}  

export function CommentPost( url,data,token) {  
  return service.post(url, data, {  
    headers: {  
      // 'Content-Type': 'multipart/form-data',
      "Authorization":token
    }  
  });  
}  