<template>
  <div id="app" style="height:100%;">
    <div style="height:100%">
      <!-- <h2 style="width: 100vw;text-align: center;">问克报表打印系统</h2>  -->
      <!-- <button @click="print">打印</button>
      <button @click="save">保存</button> -->
      <!-- <button @click="setDesigner" style="background-color: #fff;border-radius: 4px;border: 1px solid;">设置模板</button> -->
      <img v-show="previewFlag" src="/查看母版.png" alt="" @click="preview" style="cursor: pointer;" class="hoverPreview">

      <img v-show="DesignFlag" src="/设置.png" alt="" @click="setDesigner()" style="cursor: pointer;">
      <div id="viewer"></div>
    </div>
  </div>
</template>

<script>
import { DecryptHttp } from './aesSecretCode/aesCode/CryptoJS.js'
import {service} from './api/code'
import { uploadFile, reportMrtAdd, CommentPost } from './api/api';

// import {getuninvoicelist} from "./api/upload/upload"
// import { saveAs } from 'file-saver';
/* var designer = new window.Stimulsoft.Designer.StiDesigner(null, "Designer", false);

designer.onSaveAsReport = (args, callback) => {

args.preventDefault = true;

var jsonString = args.report.saveToJsonString();
console.log(jsonString,callback)
// save report

} */
export default {
  name: "app",
  data() {
    return {
      report: null,
      previewFlag: true,//是否显示预览按钮
      DesignFlag: true,//是否显示设计按钮
      token: '',
      newFlag: false,//新文件
      moduleId: '',//模块Id
      buttonId: '',//按钮Id
      buttonName: '',//按钮名称
      buttonCode: '',//按钮编码
      name: '',
      ur1: '',//mrt地址
      status: 0,//0 停用 1启用 2删除
      saveflag: false,
      mrtName:'',
      intervalPreview:null,
      generatedGuidStr: '' 
    };
  },
  async mounted() {
    this.generateGuid()
    console.log("generatedGuid",this.generatedGuidStr)
   window.Stimulsoft.Base.StiLicense.Key = service

console.log("uni传递的参数",this.$route.query)
console.log(decodeURIComponent(this.$route.query?.dataSourceUrl))

    // 设置请求的token
    if (this.$route.query?.token) {
      this.token = "Bearer " + this.$route.query?.token
    }
    if (this.$route.query?.moduleId) {
      this.moduleId = this.$route.query?.moduleId
    }
    if (this.$route.query?.buttonId) {
      this.buttonId = this.$route.query?.buttonId
    }
    if (this.$route.query?.buttonName) {
      this.buttonName = this.$route.query?.buttonName
      this.name = this.$route.query?.buttonName
    }

    if (this.$route.query?.buttonCode) {
      this.buttonCode = this.$route.query?.buttonCode
    }

    if (this.$route.query?.DesignFlag == 0) { //DesignFlag=0表示待设计
      this.newFlag = true//是否是新设计文件
      this.previewFlag = false
      this.DesignFlag = false
      // console.log("路径：",this.$route.query?.dataSourceUrl)

      this.waitDesin(decodeURIComponent(this.$route.query?.dataSourceUrl))

    } else if (this.$route.query?.DesignFlag == 1) { //DesignFlag=1表示修改
      this.previewFlag = false
      this.DesignFlag = false
      // console.log("路径：",this.$route.query?.dataSourceUrl)
      this.waitDesin(decodeURIComponent(this.$route.query?.dataSourceUrl), this.$route.query?.mrtUrl)

    }
    else if (this.$route.query?.DesignFlag == 2) {//DesignFlag=2表示预览

      this.previewFlag = false
      this.DesignFlag = false
      // console.log("路径：",this.$route.query?.dataSourceUrl)
      // this.intervalPreview = setInterval(this.preview, 5000,this.$route.query?.dataSourceUrl, this.$route.query?.mrtUrl);
      this.preview(decodeURIComponent(this.$route.query?.dataSourceUrl), this.$route.query?.mrtUrl)

    }
    else {
      // this.intervalPreview = setInterval(this.preview, 5000,this.$route.query?.dataSourceUrl, this.$route.query?.mrtUrl);

      this.preview(this.$route.query?.dataSourceUrl, this.$route.query?.mrtUrl)

    }
    // this.setViewer()
  },
  async beforeDestroy() {
    clearInterval(this.intervalPreview)
  },
  methods: {
    generateGuid() {  
      function s4() {  
        return Math.floor((1 + Math.random()) * 0x10000)  
          .toString(16)  
          .substring(1);  
      }  
      this. generatedGuidStr = s4() + s4() + '-' + s4() + '-' + s4() + '-' +  
        s4() + '-' + s4() + s4() + s4();  
    } ,
    // 预览
    preview(dataSourceUrl, mrtUrl) {

      // 本地语言设置为中文
      Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("/reports/zh-CHS.xml", true);
      // 工具栏

      // 第一个参数有一些配置项可以使用 可以传一个options 具体配置见官网
      /* 
       var options = new Stimulsoft.Viewer.StiViewerOptions(); //创建报表渲染配置对象
  // exports 是工具栏中导出按钮的配置（默认显示11种导出方式），按需进行配置显示，false代表不显示
  options.exports.showExportToHtml5 = false  
  options.exports.showExportToOpenDocumentCalc = false
  options.exports.showExportToXps = false
  options.exports.showExportDataOnly = false
  options.exports.showExportToCsv = false
  options.exports.showExportToDocument = false
  options.exports.showExportToExcel2007 = false
  options.exports.showExportToImagePng = false //导出Png图片
  options.exports.showExportToImageJpeg = false
  options.exports.showExportToImageBmp = false
  options.exports.showExportToPdf = false //导出pdf
  options.exports.showExportToPowerPoint = false //导出ppt
  options.exports.showExportToWord2007 = false //导出word
  options.exports.showExportToText = false //导出txt文件
  //options.exports.showExportDialog = false //点击导出后是否显示选项窗体
  //options.exports.showExportToHtml = false  //导出html
  //options.exports.showExportToOpenDocumentWriter = false
  //options.exports.openAfterExport = false
  //options.exports.storeExportSettings = false
  //options.exports.showOpenAfterExport = false
   options.exports.showExportToPdf = false //导出pdf
————————————————

                      
      */
      var options = new window.Stimulsoft.Viewer.StiViewerOptions(); //创建报表渲染配置对象
      options.toolbar.fontFamily = "SimHei";
      // var options = new Stimulsoft.Designer.StiDesignerOptions();
      // options.appearance.fullScreenMode = true;
      // https://www.stimulsoft.com/en/documentation/online/programming-manual/index.html?reports_js_engine_report_events.htm
      var viewer = new window.Stimulsoft.Viewer.StiViewer(
        // null,
        options,
        "StiViewer",
        false
      );

      // 报表



      var report = new window.Stimulsoft.Report.StiReport();
      report.refreshTime=2000;//设置屏幕刷新时间
      report.onRefreshing=(args)=>{
        console.log("刷新了",args)
      }
      this.intervalPreview = setInterval( report.onRefreshing=(args)=>{
        console.log("刷新了",args)
      }, 5000);
      
      // 加载文件
      report.loadFile(mrtUrl);
      report.onBeginProcessData = (args, callback) => {
        const index = args.headers.findIndex(obj => obj.key === "Authorization");
        // 在这里设置请求地址  
        if(dataSourceUrl!="undefined"){

          
        args.pathData = dataSourceUrl
      }

        // response.setHeader("Access-Control-Allow-Methods", "POST, GET, OPTIONS, DELETE, PUT");

        // 设置其他的请求头键值对   
        // args.headers.push({
        //   key: "Access-Control-Allow-Methods",
        //   value: "POST"
        // })

        // 在这里设置token
        args.headers[index].value = this.token// args.headers.push({ key: '11', value: '222' })
        // args.headers[index].value = `Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjRmMmMwYTNkLTQxNGMtNDljNS1hZDY4LWI4MzdkZDE3ODg0NyJ9.yiMaRowz1pa6tnpx_sVu44Vt3Jha9wrX7SUsChJ3umlj0dJ9DjE9aOhNktALBoR9kGoU_USrPlzm23crpbRLZQ`// args.headers.push({ key: '11', value: '222' })

        callback()
      };

      viewer.onEndProcessData = (args) => {



      }

      // this.report = report

      // 创建报表
      viewer.report = report;
      // 注入标签
    
      viewer.renderHtml("viewer");
      var elements = [...document.getElementsByClassName('stiJsViewerClearAllStyles')];  
      elements.forEach(function(element) {  
          // console.log("结点",element.innerHTML)
          // 去除打卡按钮
          if(element.innerHTML==`<div class="stiJsViewerStandartSmallButton stiJsViewerStandartSmallButtonDefault" style="font-family: SimHei; height: 23px; box-sizing: content-box; margin: 1px;"><table class="stiJsViewerClearAllStyles" cellpadding="0" cellspacing="0" style="width: 100%; height: 100%;"><tbody><tr class="stiJsViewerClearAllStyles"><td class="stiJsViewerClearAllStyles" style="line-height: 0; text-align: center; padding: 0px 4px;"><img src="data:image/webp;base64,UklGRsAAAABXRUJQVlA4TLMAAAAvD8ADEOegqJEkZcHo+X8wLt3ZUNC2jZRtB+H4I+2jKJKkZhyc/xcWcAQKcs6oYVvRMUgh/68QUoigFJZgA8Dv/1lWtibpuh1JJ+mknaSTCKkx5P35qgpmk3bW5yT4cACDQwD0Dw9LQil6hMKkrxYMI0lSsu+Pw+KSf6IvWAIR/Z8AfJ/hkiWEkAPjI0BJsvcqdBPAq1HpagVY+oxuB9ialCYA9ipt56DJKLbAZ9RHkH3ihwA=" style="width: 16px; height: 16px; transform: scale(0.8);"></td><td class="stiJsViewerClearAllStyles" style="padding: 0px 10px 0px 0px; white-space: nowrap; text-align: left;">打开</td></tr></tbody></table></div>`){
            element.style.display="none"
          }
          // 去除问号按钮
          if(element.innerHTML==`<table class="stiJsViewerClearAllStyles" cellpadding="0" cellspacing="0" style="margin: 1px;"><tbody><tr class="stiJsViewerClearAllStyles"><td class="stiJsViewerClearAllStyles"><div class="stiJsViewerStandartSmallButton stiJsViewerStandartSmallButtonDefault" style="font-family: SimHei; height: 23px; box-sizing: content-box; margin: 1px;"><table class="stiJsViewerClearAllStyles" cellpadding="0" cellspacing="0" style="width: 100%; height: 100%;"><tbody><tr class="stiJsViewerClearAllStyles"><td class="stiJsViewerClearAllStyles" style="line-height: 0; text-align: center; padding: 0px 4px;"><img src="data:image/webp;base64,UklGRmwAAABXRUJQVlA4TGAAAAAvD8ADEHUwbNvG0crfEu2PB1Jugn4XgZC21SiE8BE+wkfJYKHMYEOYQQhDCCGEJqCWCoS2SdKDElr08i+r7GHpqFbATMOfRVX7aRo2cRpVO8mpTvHmEQLHZQzPUTWQJxE=" style="width: 16px; height: 16px; transform: scale(0.8);"></td></tr></tbody></table></div></td></tr></tbody></table>`){
            element.style.display="none"

          }
          
      });
      

      
    },

   
    async waitDesin(dataSourceUrl, mrtUrl) {


      Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("/reports/zh-CHS.xml", true);
      let that = this

      // 数据源字典隐藏

      // 第一个参数有一些配置项可以使用 可以传一个options 具体配置见官网
      // https://www.stimulsoft.com/en/documentation/online/programming-manual/index.html?reports_js_engine_report_events.htm   
      var options = new window.Stimulsoft.Designer.StiDesignerOptions();
      options.toolbar.fontFamily = "SimHei";

      // options.appearance.fullScreenMode = true;

      // options.dictionary.showDictionaryContextMenuProperties=false 
      // options.dictionary.showDictionaryActions=false 
      // options.dictionary.showDictionary=false 
      // options.dictionary.showAdaptersInNewConnectionForm=false 
      var designer = new window.Stimulsoft.Designer.StiDesigner(options, "Designer", false);
      var report = new window.Stimulsoft.Report.StiReport();
      var elements2 = [...document.getElementsByClassName('DesignerFileMenuIteminfoReport')];  
      elements2.forEach(function(element) {  
          console.log("结点",element.innerHTML)
          // 去除打卡按钮
          // if(element.innerHTML==`<div class="stiJsViewerStandartSmallButton stiJsViewerStandartSmallButtonDefault" style="font-family: SimHei; height: 23px; box-sizing: content-box; margin: 1px;"><table class="stiJsViewerClearAllStyles" cellpadding="0" cellspacing="0" style="width: 100%; height: 100%;"><tbody><tr class="stiJsViewerClearAllStyles"><td class="stiJsViewerClearAllStyles" style="line-height: 0; text-align: center; padding: 0px 4px;"><img src="data:image/webp;base64,UklGRsAAAABXRUJQVlA4TLMAAAAvD8ADEOegqJEkZcHo+X8wLt3ZUNC2jZRtB+H4I+2jKJKkZhyc/xcWcAQKcs6oYVvRMUgh/68QUoigFJZgA8Dv/1lWtibpuh1JJ+mknaSTCKkx5P35qgpmk3bW5yT4cACDQwD0Dw9LQil6hMKkrxYMI0lSsu+Pw+KSf6IvWAIR/Z8AfJ/hkiWEkAPjI0BJsvcqdBPAq1HpagVY+oxuB9ialCYA9ipt56DJKLbAZ9RHkH3ihwA=" style="width: 16px; height: 16px; transform: scale(0.8);"></td><td class="stiJsViewerClearAllStyles" style="padding: 0px 10px 0px 0px; white-space: nowrap; text-align: left;">打开</td></tr></tbody></table></div>`){
          //   element.style.display="none"
          // }
          // // 去除问号按钮
          // if(element.innerHTML==`<table class="stiJsViewerClearAllStyles" cellpadding="0" cellspacing="0" style="margin: 1px;"><tbody><tr class="stiJsViewerClearAllStyles"><td class="stiJsViewerClearAllStyles"><div class="stiJsViewerStandartSmallButton stiJsViewerStandartSmallButtonDefault" style="font-family: SimHei; height: 23px; box-sizing: content-box; margin: 1px;"><table class="stiJsViewerClearAllStyles" cellpadding="0" cellspacing="0" style="width: 100%; height: 100%;"><tbody><tr class="stiJsViewerClearAllStyles"><td class="stiJsViewerClearAllStyles" style="line-height: 0; text-align: center; padding: 0px 4px;"><img src="data:image/webp;base64,UklGRmwAAABXRUJQVlA4TGAAAAAvD8ADEHUwbNvG0crfEu2PB1Jugn4XgZC21SiE8BE+wkfJYKHMYEOYQQhDCCGEJqCWCoS2SdKDElr08i+r7GHpqFbATMOfRVX7aRo2cRpVO8mpTvHmEQLHZQzPUTWQJxE=" style="width: 16px; height: 16px; transform: scale(0.8);"></td></tr></tbody></table></div></td></tr></tbody></table>`){
          //   element.style.display="none"

          // }
          
      });
      if (mrtUrl) {// 修改
        // mrtUrl+=`?v=${Date.now()}`
        report.loadFile(mrtUrl+`?v=${Date.now()}`);
         // http://192.168.3.203:7001/rdf/tag/list
        //  let resJson= await CommentPost(dataSourceUrl,{},that.token)
        //  resJson=JSON.stringify(resJson.rows)
        console.log("数据源123213",dataSourceUrl)
        // report.dictionary.databases.remove("viewReport");
        var database = new window.Stimulsoft.Report.Dictionary.StiJsonDatabase("viewReport", dataSourceUrl);
        // 设置请求头
        database.headers.push({
          key: "Authorization",
          value: this.token
        })
        
        // report.dictionary.databases.list[0].pathData=dataSourceUrl
        // report.dictionary.databases.list[0].inherited=true
        // // report.dictionary.databases.list[0].relationDirection=2
        // report.dictionary.databases.list[0].headers.map(item=>{
        //     if(item.key=='Authorization'){
        //       item.value= this.token
        //     }
        // })
        // console.log(" report.dictionary.databases",report.dictionary.databases.list[0].headers)
        // console.log(" report.dictionary.databases",report.dictionary.databases.dictionary)
        // report.dictionary.databases.list[0]=database
        // 添加到数据源
        report.dictionary.clear();
        report.dictionary.databases.add(database);
        // report.regData("DemoData123423", "", database);

        report.dictionary.synchronize();
        //得到数据源字段


        // args.report.dictionary.synchronize();

      } else {//新文件

        report.loadFile("https://wensanshi-dev.oss-cn-shenzhen.aliyuncs.com/eeb52cff/sdf/mrt/2024/3/22/216212e24f1b4b1caa40c5abcf302c84");
        // http://192.168.3.203:7001/rdf/tag/list
        //  let resJson= await CommentPost(dataSourceUrl,{},that.token)
        //  resJson=JSON.stringify(resJson.rows)
        console.log("数据源123213",dataSourceUrl)
        var database = new window.Stimulsoft.Report.Dictionary.StiJsonDatabase("数据", dataSourceUrl);
        // 设置请求头
        database.headers.push({
          key: "Authorization",
          value: this.token
        })

        // 添加到数据源
        report.dictionary.databases.add(database);
        report.dictionary.synchronize();
        // report.regData("DemoData123423", "", resJson);
        //得到数据源字段


        // args.report.dictionary.synchronize();


      }

      // 加载文件
      // report.loadFile("https://wensanshi-dev.oss-cn-shenzhen.aliyuncs.com/99b21c69/sdf/mrt/2024/3/19/de3784b0e4c34d738bc7b85f5ccaa8a7");

      designer.report = report;

      // designer.onBeginProcessData = (args) => {

      // };

      // 设计保存时调用
      designer.onSaveReport = async (args) => {
        // if (that.saveflag) {

        //   window.alert('请勿重复保存设计，请返回！');
        //   return
        // }
        args.autoSave = true
        var report = args.report.clone();

        report.dictionary.resources.clear();

        var jsonReport = args.report.saveToJsonString();
        const blob = new Blob([jsonReport], { type: 'application/json' });

        var formData = new FormData();
        if (this.newFlag&&this.mrtName=='') {

          formData.append('file', blob, '9999999999999999999.mrt');

        } else {
          if(this.mrtName){
            formData.append('file', blob, this.mrtName.split('/').at(-1));

          }else{
          formData.append('file', blob, mrtUrl.split('/').at(-1));
        }
        }

        args.report = report;

        // 这里调取保存的接口，需要获取uniapp登录的token,在web-view可以传递 
        // let token = this.token  
        const response = await uploadFile(formData, that.token);
        //  对数据解密
        let res
        if(response.code==200){
          window.alert('文件上传成功！');

        }
        if(response.code==500){
          window.alert('您提交太快了，请稍等！');

        }
        if (process.env.VUE_APP_ENV == 'production') { 
          res = DecryptHttp(response) || response
        } else {
          res = response
        }
        // 新增
        if (this.newFlag) {
          // https://wensanshi-dev.oss-cn-shenzhen.aliyuncs.com/sdf/mrt/1772470529575268352.mrt

          // return
          let requestObj = {
            id:res.msg.split('/').at(-1).split('.').at(0),
            moduleId: that.moduleId,//模块Id
            buttonId: that.buttonId,//按钮Id
            buttonName: that.buttonName,//按钮名称
            buttonCode: that.buttonCode,//按钮编码
            name: that.buttonName,
            url: res.msg,//mrt地址
            status: 1,//0 停用 1启用 2删除
          }

          let result = await reportMrtAdd(requestObj, that.token)

          if (result.code == 200) {
            this.mrtName=res.msg
            that.saveflag = true
            window.alert('保存成功！');

          }
        } else {
         /*  let requestObj = {
            moduleId: that.moduleId,//模块Id
            buttonId: that.buttonId,//按钮Id
            buttonName: that.buttonName,//按钮名称
            buttonCode: that.buttonCode,//按钮编码
            name: that.buttonName,
            url: res.msg,//mrt地址
            status: 1,//0 停用 1启用 2删除
          }

          let response2 = await reportMrtAdd(requestObj, that.token)
          if (process.env.VUE_APP_ENV == 'production') { result = DecryptHttp(response2)|| response2} else {
            result = response2
        }

          if (result.code == 200) {
            that.saveflag = true
            window.alert('保存成功！');
          } */
        }
      }



      designer.onEndProcessData = (args) => {


      }



      designer.renderHtml("viewer");



    }


  },
};

</script>

<style>
html,body{
  padding:0;
  margin:0;
  width: 100%;
  height: 100%;
}
#Designer{
  height:100% !important;
}

#DesignerFileMenuItemhelp{
	display:none;
}

#app {
  font-size: 16px;
  width: 100%;
  height: 100%;
}

#viewer {
  width: 100%;
  height: 100%;
}
#DesignereditConnectionForm{
  /* display: none; */

}
#DesignerfileButton{
  /*display: none;*/

}
/* 隐藏字典编辑 */
.stiDesignerControlCellsBigIntervals2{
  display: none;
}
.stiDesignerCaptionControlsBigIntervals {
  display: none;
}
/* ---------------------------------- */
/* 隐藏文件关于信息 */
.stiDesignerAboutPanelHeader {
  display: none;
}
.stiDesignerAboutPanelCopyright {
  display: none;
}
.stiDesignerAboutPanelVersion{
  display: none;

}
.stiDesignerAboutPanelStiLink{
  display: none;
}
/* ---------------------------------- */


.stiJsViewerAboutPanelHeader{
  /* opacity: 0; */
  display: none;

}
.stiJsViewerAboutPanelCopyright{
  display: none;

}
.stiJsViewerAboutPanelVersion{
  display: none;

}
.stiJsViewerAboutPanelStiLink{
  display: none;

}
</style>
