export default{
	// url_config:"http://47.106.222.181:8888",
	// static_url:"http://47.106.222.181:8888",
	// wss_url:"ws://47.106.222.181:8889",
	// wss_url2: 'ws://47.106.222.181:8890',
	// url_config: `https://wss.thisdemo.net/test-api`,
	// static_url: `https://wss.thisdemo.net/test-api`,
	// wss_url: 'wss://wss.thisdemo.net/om',
	// wss_url2: 'wss://wss.thisdemo.net/im',
	// url_config: `https://20d37u4572.oicp.vip`,
	// static_url: `https://20d37u4572.oicp.vip`,
	url_config: `https://www.wensanshi.com/prod-api`,
	static_url: `https://www.wensanshi.com/prod-api`,
	// wss_url: 'ws://www.wensanshi.com',
	wss_url: 'wss://www.wensanshi.com/im',
	wss_url2: 'wss://www.wensanshi.com/im',
	// wss_url: 'ws://www.wensanshi.com:8889',
	// wss_url2: 'ws://www.wensanshi.com:8890',
	// url_config: "http://192.168.3.203:7001",//雷公
	// static_url: "http://192.168.3.203:7001",
	// wss_url:"ws://192.168.3.203:8002",
	// wss_url2:"ws://192.168.3.203:8002",
	// url_config: "http://101.43.126.63:7001", //雷工 云服务器
	// static_url: "http://101.43.126.63:7001",
	// wss_url:"ws://101.43.126.63:7002",
	// wss_url2:"ws://101.43.126.63:7003",
  // url_config: "http://localhost:7001", //雷工 云服务器
  // static_url: "http://localhost:7001",
} 